import React from "react";
import { mapItems } from "@insightfulscience/contentful/Apps/components/FeaturedContentBlock/mapper";
import FeaturedContentItems from "@insightfulscience/contentful/Apps/components/FeaturedContentBlock/FeaturedContentItems";
import FeaturedContentBlockDefault from "@insightfulscience/contentful/Apps/components/FeaturedContentBlock/FeaturedContentBlock";
import Template from "@insightfulscience/contentful/Apps/components/Template";

const FeaturedContentBlock = ({
	id,
	name,
	subname,
	contentReference = [],
	components,
	...props
}) => {
	const SectionTemplate = components?.sectionTemplate || Template;
	return (
		<SectionTemplate title={name} subtitle={subname} anchor={id}>
			<FeaturedContentItems
				items={mapItems(contentReference)}
				imgSpacing={{ p: 4 }}
				imgWrapperUtility={{ display: "flex", justify: "center", align: { items: "center" } }}
				{...props}
				isImgFluid
			/>
		</SectionTemplate>
	);
};

FeaturedContentBlock.propTypes = FeaturedContentBlockDefault.propTypes;

export default FeaturedContentBlock;
