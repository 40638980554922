import React from "react";
import contentfulStandardApp from "@insightfulscience/contentful/Apps/Standard";
import AppError from "@src/AppError";
import pipe from "@insightfulscience/shared-utils/fn/pipe";
import Hero from "@insightfulscience/atomic-react/Hero";
import { HeroCol, HeroRow } from "@insightfulscience/atomic-react/Hero/Grid";
import Image from "@insightfulscience/atomic-react/Image";
import HeroLead from "@insightfulscience/atomic-react/Hero/Lead";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { node } from "prop-types";
import SiteLayout from "../../SiteLayout";
import WithServices from "../../services";
import withErrorHandling from "../../AppError/withErrorHandling";
import PrismAcademyTemplate from "../../Contentful/PrismAcademyTemplate";
import PrismAcademyFeaturedContentBlock from "../../Contentful/PrismAcademyFeaturedContentBlock";
import LinksBlock from "../../Contentful/LinksBlock";

const PrismAcademyLayout = ({ children }) => {
	const [t] = useLocale("prism-academy");

	return (
		<SiteLayout>
			<Hero color="gradient">
				<HeroRow>
					<HeroCol md={8}>
						<Image
							src="/images/graphpad/logo/text-logo-prism-academy.svg"
							alt={t("PRISM_ACADEMY")}
						/>
						<HeroLead>{t("LEAD")}</HeroLead>
					</HeroCol>
				</HeroRow>
			</Hero>
			{children}
		</SiteLayout>
	);
};

PrismAcademyLayout.propTypes = {
	children: node.isRequired,
};

const PrismAcademyApp = contentfulStandardApp({
	Layout: PrismAcademyLayout,
	AppError,
	contentfulProps: {
		environment: process.env.CTF_ENV,
		space: process.env.CTF_SPACE,
		accessToken: process.env.CTF_TOKEN,
	},
	pageProps: {
		brandCode: "gp",
		siteDomain: "https://www.graphpad.com",
		components: {
			sectionTemplate: PrismAcademyTemplate,
			linksBlock: LinksBlock,
			blockFeaturedContent: PrismAcademyFeaturedContentBlock,
		},
		slug: "prism-academy",
	},
});

export default pipe(PrismAcademyApp, withErrorHandling, WithServices);
