import React from "react";
import PageNavbar from "@insightfulscience/atomic-react/PageNavbar";
import PageNavbarItem from "@insightfulscience/atomic-react/PageNavbar/Item";
import PageNavbarLink from "@insightfulscience/atomic-react/PageNavbar/Link";
import LinksBlockDefault from "@insightfulscience/contentful/Apps/components/LinksBlock";

const LinksBlock = ({ linkReference, stickyToc = false }) => {
	return (
		<PageNavbar alignHorizontal="around" utility={{ bgColor: "5--12" }} sticky={stickyToc}>
			{linkReference.map(({ fields: { linkText, linkUrl } }) => (
				<PageNavbarItem key={linkUrl}>
					<PageNavbarLink href={linkUrl}>{linkText}</PageNavbarLink>
				</PageNavbarItem>
			))}
		</PageNavbar>
	);
};

LinksBlock.propTypes = LinksBlockDefault.propTypes;

export default LinksBlock;
