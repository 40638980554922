import React from "react";
import LandingSimpleSectionStripped from "@insightfulscience/atomic-react/LandingElement/SimpleSectionStripped";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import TemplateDefault from "@insightfulscience/contentful/Apps/components/Template";

const Template = ({
	title,
	subtitle,
	backgroundColor,
	anchor,
	children,
	textAlign = "center",
	containerProps,
}) => {
	return (
		<LandingSimpleSectionStripped bgColor={backgroundColor} containerProps={containerProps}>
			{anchor && (
				<a name={anchor} href="/">
					{" "}
				</a>
			)}
			{title && (
				<LandingSimpleSection.TitleV2 sx={{ textAlign }} spacing={{ mb: 0 }}>
					{title}
				</LandingSimpleSection.TitleV2>
			)}
			{subtitle && (
				<LandingSimpleSection.DescriptionV2 sx={{ textAlign }}>
					{subtitle}
				</LandingSimpleSection.DescriptionV2>
			)}
			<LandingSimpleSection.Body>{children}</LandingSimpleSection.Body>
		</LandingSimpleSectionStripped>
	);
};

Template.propTypes = TemplateDefault.propTypes;

export default Template;
